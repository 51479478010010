import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useComponentSize from '@rehooks/component-size'
import ProjectImage from './ProjectImage'

const List = styled.div`
  grid-column: 2 / span 3;
  position: relative;
  height: 70vh;
  background: ${props => props.theme.color.bg};
`;


const Collage = ({ images }) => {

  let ref = useRef(null)
  let size = useComponentSize(ref)

  let { width, height } = size

  const [topZ, setTopZ] = useState(1)

  return (
    <List className='draggable-bounds' ref={ref}>
      {width > 0 && images.map(({ image }, index) => {

        return (
          <ProjectImage
            topZ={topZ}
            setTopZ={setTopZ}
            parentWidth={width}
            parentHeight={height}
            key={index}
            title={image.alt}
            file={image.url}
            handleDetail={() => console.log('clicked')}
          />
        )
      })}
    </List>
  )
}

export default Collage;
