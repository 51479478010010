import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Video = styled.div`
  width: 100%;

  .player {
    width: 100% !important;
    height: 37.62vw !important;
  }
`;

const Poster = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 0 auto 4rem;

  img {
    width: 100%;
  }
`;

const DetailsVideo = ({ url, poster, title }) => {
  const [playing, setPlaying] = useState(false)

  return (
    <Video>
      {playing
        ?
          <ReactPlayer
            className='player'
            url={url}
            playing={playing}
          />
        :

          <Poster onClick={() => setPlaying(true)}>
            <img src={poster} alt={title} />
          </Poster>
      }
    </Video>
  )
}
export default DetailsVideo;
