import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device } from '../utils/devices'

const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  grid-gap: 2.5vw;
  align-items: center;
  box-sizing: border-box;
  padding: 10vh 5vw 4rem;
  color: ${props => props.theme.color.text};

  @media ${device.laptop}{
    padding: 10vh 0 0;
    grid-template-columns: repeat(4, 1fr);
  }

  &:after {
    content: "";
    width: 50vw;
    height: 2px;
    background: ${props => props.theme.color.heading};
    margin: 5vh auto;
    grid-column: 1 / span 4;
  }

  a {
    color: ${props => props.theme.color.text};
  }

  h1 {
    grid-column:  2/ span 2;
    color: ${props => props.theme.color.heading};
    font-family: 'Ogg';
    text-align: center;
    font-size: 6vh;
    line-height: 5vh;
    font-weight: normal;
    margin: 0;

    @media ${device.laptop}{
      font-size: 20vh;
      line-height: 15vh;
    }
  }

  span {
    font-weight: 400;
    font-size: 16px;

    @media ${device.laptop}{
      font-size: 1.5vw;
    }

    &.left {
      text-align: right;
    }

    &.right {
      text-align: left;
    }
  }
`;

const Header = () => (
  <Head>
    <span className='left'>Creative Strategy</span>
    <h1>Julian</h1>
    <span className='right'>New York</span>
    <span className='left'><Link to ='/about'>About</Link></span>
    <h1>Isaac</h1>
    <span className='right'><a href="mailto:me@julianisaac.com">Contact</a></span>
    <h1>Estrada</h1>
    <h1>Ortega</h1>
  </Head>
);

export default Header;
