import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.button`
  padding: 1rem 2rem;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.text};
  appearance: none;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  max-width: fit-content;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  outline: 0;
  color: ${props => props.theme.color.text};

  &:hover {
    background: ${props => props.theme.color.text};
    color: ${props => props.theme.color.bg};
  }
`;

const Button = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default Button
