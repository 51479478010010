import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { Link, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import DetailsVideo from '../components/DetailsVideo'
import DetailsAudio from '../components/DetailsAudio'

const apiEndpoint = 'https://julian-isaac.cdn.prismic.io/api/v2'
const Client = Prismic.client(apiEndpoint)

const Back = styled.div`
  font-size: 1.25vw;
  margin-bottom: 5vh;

   a {
     color: rgb(24,80,140);
   }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: fill-available;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2.5vw;
  background: white;
  color: rgb(24,80,140);
  padding: 5vh 5vw;
`;

const Info = styled.div`
  position: sticky;
  top: 5vh;
  height: fill-available;

  ul {
    padding: 0;
    margin: 2rem 0 0 0;
    list-style: none;
    font-size: 2vh;
    line-height: 2.5vh;
  }
`;

const Item = styled.li`
  color: ${props => props.active ? props.theme.color.heading : `rgb(24,80,140)` };
  cursor: pointer;

  span {
    font-family: 'TN';
    font-weight: 400;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;

  h1 {
    margin: 0 2rem 0 0;
    font-size: 5vh;
    font-weight: normal;
    text-transform: capitalize;
  }

  time {
    font-family: ${props => props.theme.type.text};
    font-size: 3vh;
    font-weight: 200;
    line-height: 5vh;
  }
`;

const Desc = styled.div`
  margin-top: 2rem;
  font-size: 1.5vh;
  line-height: 2.3vh;
`;

const Content = styled.div`
  grid-column: 2 / span 3;
  display: flex;
  height: 100%;
  height: fill-avaiable;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 1rem;
  }
`;

const Gallery = styled.div`
  width: 100%;
  position: relative;

  .slick-arrow {
    outline: none;
    position: absolute;
    z-index: 2000;
    top: 0;
    height: 100%;
    width: 50%;
    appearance: none;
    background: transparent;
    border: 0;
    text-indent: -9999em;

    &.slick-prev {
      left: 0;
      cursor: w-resize;
    }

    &.slick-next {
      right: 0;
      cursor: e-resize;
    }
  }

  img {
    width: 100%;
    height: 90vh;
    height: fill-avaiable;
    object-fit: contain;
  }
`;

const Document = styled.div`

  img {
    max-height: 80vh;
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
`;

const Project = () => {

  const [docs, setDocs] = useState([])
  const { type } = useParams();
  const [activeDoc, setActiveDoc] = useState(0)

  useEffect(() => {

    // Fetch all projects from Prismic
    const fetchData = async () => {
      const res = await Client.query(
        Prismic.Predicates.at('document.type', 'project'),
        { orderings: '[my.project.date desc]'}
      )

      if(res){

        // Map through the projects and look for matching type
        res.results.map( async project => {
          return project.data.body.map( async doc => {

            // If we're on the tone, just get audio slices
            if(type === 'tone' && doc.slice_type === 'audio'){
              setDocs(prevDocs => setDocs([...prevDocs, doc]))
            }

            // If we're on frame, get videos and screenplay docs
            if(type === 'frame' && (doc.slice_type === 'video' || doc.primary.type === 'Frame')){
              setDocs(prevDocs => setDocs([...prevDocs, doc]))
            }

            // If we're on form, get galleries and fashion docs
            if(type === 'form' && (doc.slice_type === 'gallery' || doc.primary.type === 'Form')){
              setDocs(prevDocs => setDocs([...prevDocs, doc]))
            }
          })
        })
      }
    }

    fetchData()
  }, [type])

  const renderDoc = (i) => {
    switch (i.slice_type) {
      case 'video':
        return <DetailsVideo url={i.primary.url.embed_url} poster={i.primary.thumbnail.url} />
      case 'audio':
      console.log(i.primary)
        return <DetailsAudio url={i.primary.file.url} poster={i.primary.thumbnail.url}/>
      case 'gallery':
        return (
          <Gallery>
            <Slider>
              {i.items.map((item, index) => <img key={index} src={item.image.url} alt={item.image.alt} />)}
            </Slider>
          </Gallery>
        )
      default:
        return (
          <Document>
            <a href={i.primary.file.url} target="_blank" rel="noopener noreferrer">
              <img src={i.primary.thumbnail.url} alt={i.primary.title1[0].text} />
            </a>
          </Document>
        )
    }
  }

  const renderType = (type) => {
    console.log(type)
    switch (type) {
      case 'audio':
        return <span> – Audio ▹</span>
      case 'gallery':
        return <span> — Gallery ◰</span>
      default:
        return <span> – Document ◰</span>
    }
  }

  return (
    <Wrapper>
      {docs.length > 0 &&
        <>
          <Info>
            <Back><Link to='/'>⤺ Back</Link></Back>
            <Title>
              <h1>{type}</h1>
            </Title>
            <Desc>
              <ul>
                {docs.map((i, index) => {
                  return (
                    <Item
                      key={index}
                      active={activeDoc === index}
                      onClick={() => setActiveDoc(index)}
                      >
                        {i.primary.title1[0].text}
                        {renderType(i.slice_type)}
                    </Item>
                  )
                })}
              </ul>
            </Desc>
          </Info>
          <Content>
            {renderDoc(docs[activeDoc])}
          </Content>
        </>
      }
    </Wrapper>
  )
}

export default Project
