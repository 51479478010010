import React from 'react'
import styled from 'styled-components'
import  Slider from 'react-slick'
import "slick-carousel/slick/slick.css"

const Details = styled.div`

  img {
    height: 70vh;
    width: 40vw !important;
    object-fit: contain;
  }

  .slick-arrow {
    outline: none;
    position: fixed;
    z-index: 2000;
    top: 0;
    height: fill-available;
    width: 50vw;
    appearance: none;
    background: transparent;
    border: 0;
    text-indent: -9999em;

    &.slick-prev {
      left: 0;
      cursor: w-resize;
    }

    &.slick-next {
      right: 0;
      cursor: e-resize;
    }
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 3000;
  left: 5vw;
  font-size: 2vw;
  bottom: 2.5vw;
  font-family: ${props => props.theme.type.text};
`;

const DetailsGallery = ({ images, title }) => (
  <Details>
    <Title>{title}</Title>
    <Slider
      slidesToShow={2}
      slidesToScroll={2}
    >
      {images.map(image => <img src={image.image.url} alt={image.image.alt} />)}
    </Slider>
  </Details>
);

export default DetailsGallery
