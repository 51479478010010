import React, { useState } from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Project from './pages/Project'
import Type from './pages/Type'
import Password from './components/Password'

const Main = styled.div`
  background: ${props => props.theme.color.bg};
`;

const App = () => {

  const [access, setAccess] = useState(false)
  const [error, setError] = useState(false)

  const theme = {
    type: {
      heading: `'Ogg', 'TN', Georgia, serif`,
      text: `'AG', 'Helvetica Neue', Helvetica, Arial, sans-serif`
    },
    color: {
      bg: `#f8e4b5`,
      heading: `rgb(242,17,17)`,
      text: `rgb(24,123,140)`
    }
  }

  const handlePassword = (e, value) => {
    e.preventDefault();
    console.log('submitted!')

    if(value === 'pursuit'){
      setAccess(true)
      setError(false)
    } else {
      setError(true)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Password access={access} error={error} handlePassword={handlePassword} />
      <Main>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/about' component={About} />
          <Route path='/work/:uid' component={Project} />
          <Route path='/filter/:type' component={Type} />
        </Switch>
      </Main>
    </ThemeProvider>
  );
}

export default App;
