import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { createBrowserHistory as createHistory } from 'history'
import { BrowserRouter, Router } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

const history = createHistory()

ReactDOM.render(
  <BrowserRouter>
    <Router history={history}>
      <App />
    </Router>
  </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
