import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { device } from '../utils/devices'

const Wall = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  background: ${props => props.theme.color.bg};
  z-index: 9999;
  display: ${props => props.hide ? `none` : `flex`};
  align-items: center;
  justify-content: center;

  @media ${device.laptop}{
    width: 100vw;
    height: 100vh;
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Input = styled.input`
  font-size: 16px;
  border: 2px solid ${props => props.theme.color.text};
  color: ${props => props.theme.color.text};
  padding: 1vw;
  /* background: transparent; */
  outline: 0;
  appearance: none;
  border-radius: 3px;
  width: 20vw;
  margin-right: 2vw;
`;

const Error = styled.div`
  padding: 2rem 0;
  color: ${props => props.theme.color.heading};
  font-size: 1.5vw;
  position: absolute;
  top: 5vh;
  width: 100vw;
  text-align: center;
`;

const Password = ({ access, error, handlePassword }) => {

  const [password, setPassword] = useState('')

  return (
    <Wall hide={access}>
      <Form onSubmit={e => handlePassword(e, password)}>
        <Input type='password' value={password} placeholder='Password' onChange={e => setPassword(e.target.value)} />
        <Button>Submit</Button>
        {error &&
          <Error>Sorry, try again</Error>
        }
      </Form>
    </Wall>
  )
}

export default Password;
