import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { Link, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import DetailsVideo from '../components/DetailsVideo'
import DetailsAudio from '../components/DetailsAudio'
import { device } from '../utils/devices'

const apiEndpoint = 'https://julian-isaac.cdn.prismic.io/api/v2'
const Client = Prismic.client(apiEndpoint)

const Back = styled.div`
  font-size: 16px;
  margin-bottom: 5vh;

  @media ${device.laptop}{
    font-size: 1.25vw;
  }

   a {
     color: rgb(24,80,140);
   }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: fill-available;
  background: white;
  color: rgb(24,80,140);
  padding: 5vh 5vw;

  @media ${device.laptop}{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 2.5vw;
  }
`;

const Info = styled.div`
  position: sticky;
  top: 5vh;
  height: fill-available;

  ul {
    padding: 0;
    margin: 2rem 0 0 0;
    list-style: none;
    font-size: 2vh;
    line-height: 2.5vh;
  }
`;

const Item = styled.li`
  color: ${props => props.active ? props.theme.color.heading : `rgb(24,80,140)` };
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;

  h1 {
    margin: 0 2rem 0 0;
    font-size: 5vh;
    font-weight: normal;
  }

  time {
    font-family: ${props => props.theme.type.text};
    font-size: 3vh;
    font-weight: 200;
    line-height: 5vh;
  }
`;

const Desc = styled.div`
  margin-top: 2rem;
  font-size: 1.5vh;
  line-height: 2.3vh;
`;

const Content = styled.div`
  grid-column: 2 / span 3;
  display: flex;
  height: 100%;
  height: fill-avaiable;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 1rem;
  }
`;

const Gallery = styled.div`
  width: 100%;
  position: relative;

  .slick-arrow {
    outline: none;
    position: absolute;
    z-index: 2000;
    top: 0;
    height: 100%;
    width: 50%;
    appearance: none;
    background: transparent;
    border: 0;
    text-indent: -9999em;

    &.slick-prev {
      left: 0;
      cursor: w-resize;
    }

    &.slick-next {
      right: 0;
      cursor: e-resize;
    }
  }

  img {
    width: 100%;
    height: 90vh;
    height: fill-avaiable;
    object-fit: contain;
  }
`;

const Document = styled.div`

  img {
    max-height: 80vh;
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
`;

const Project = () => {

  const [project, setProject] = useState(null)
  const [activeDoc, setActiveDoc] = useState(0)
  const { uid } = useParams()

  useEffect(() => {
    const fetchProject = async () => {
      const doc = await Client.getByUID('project', uid)

      if(doc){
        setProject(doc)
      }
    }

    fetchProject()
  }, [uid])

  const renderDoc = (i) => {
    switch (i.slice_type) {
      case 'video':
        return <DetailsVideo url={i.primary.url.embed_url} poster={i.primary.thumbnail.url} />
      case 'audio':
      console.log(i.primary)
        return <DetailsAudio url={i.primary.file.url} poster={i.primary.thumbnail.url}/>
      case 'gallery':
        return (
          <Gallery>
            <Slider>
              {i.items.map((item, index) => <img key={index} src={item.image.url} alt={item.image.alt} />)}
            </Slider>
          </Gallery>
        )
      default:
        return (
          <Document>
            <a href={i.primary.file.url} target="_blank" rel="noopener noreferrer">
              <img src={i.primary.thumbnail.url} alt={i.primary.title1[0].text} />
            </a>
          </Document>
        )
    }
  }

  const renderType = (type) => {
    switch (type) {
      case 'audio':
        return <span> – Audio ▹</span>
      case 'gallery':
        return <span> — Gallery ◰</span>
      default:
        return <span> – Document ◰</span>
    }
  }

  return (
    <Wrapper>
      {project &&
        <>
          <Info>
            <Back><Link to='/'>⤺ Back</Link></Back>
            <Title>
              <h1>{project.data.title[0].text}</h1>
              <Moment format='Y'>{project.data.date}</Moment>
            </Title>
            <Desc>
              {project.data.description.map((p, index) => <p key={index}>{p.text}</p>)}
              <ul>
                {project.data.body.map((i, index) => {
                  return (
                    <Item
                      key={index}
                      active={activeDoc === index}
                      onClick={() => setActiveDoc(index)}
                      >
                        {i.primary.title1[0].text}
                        {renderType(i.slice_type)}
                    </Item>
                  )
                })}
              </ul>
            </Desc>
          </Info>
          <Content>
            {renderDoc(project.data.body[activeDoc])}
          </Content>
        </>
      }
    </Wrapper>
  )
}

export default Project
