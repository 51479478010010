import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device } from '../utils/devices'

const List = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2.5vw;
  padding: 0 0 8rem;

  li {
    text-align: center;
    font-size: 5vw;

    @media ${device.laptop} {
      font-size: 1.5vw
    }
  }
`;

const Nav = () => (
  <List>
    <li>All</li>
    <li><Link to='/filter/form'>Form</Link></li>
    <li><Link to='/filter/tone'>Tone</Link></li>
    <li><Link to='/filter/frame'>Frame</Link></li>
  </List>
);

export default Nav
