import React, { forwardRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import Project from './Project'
import Nav from './Nav'
import SectionTitle from './SectionTitle'
import DetailsGallery from './DetailsGallery'
import DetailsDocument from './DetailsDocument'

const apiEndpoint = 'https://julian-isaac.cdn.prismic.io/api/v2'
const Client = Prismic.client(apiEndpoint)

const Wrapper = styled.div`
  width: 80vw;
  min-width: 300px;
  margin: 0 auto;
  padding-bottom: 10vh;
  font-size: 24px;
`;

const Details = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: fill-available;
  background: white;
  z-index: 1000;
  box-sizing: border-box;
  padding: 5vw;
  /* visibility: ${props => props.open ? `visible` : `hidden` }; */
  transition: 0.2s all ease-in-out;
  transform: translate(${props => props.open ? `0, 0` : `0, 100%` });
  opacity: ${props => props.open ? 1 : 0 };

  .close {
    position: absolute;
    top: 5vw;
    right: 5vw;
    font-size: 2vw;
    appearance: none;
    border: 0;
    background: transparent;
    outline: 0;
    cursor: pointer;
    z-index: 9999999;
  }
`;

const Projects = forwardRef((props, ref) => {

  const [projects, setProjects] = useState([])
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [activeProject, setActiveProject] = useState(null)

  const handleDetail = (project) => {
    setActiveProject(project)
    setDetailsOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await Client.query(
        Prismic.Predicates.at('document.type', 'project'),
        { orderings: '[my.project.date desc]'}
      )

      if(res){
        setProjects(res.results)
      }
    }

    fetchData()
  }, [])

  const renderDetails = () => {
    switch (activeProject.slice_type) {
      case 'gallery':
        return <DetailsGallery images={activeProject.items} title={activeProject.primary.title1[0].text} />
      default:
        return <DetailsDocument file={activeProject.primary.file.url} title={activeProject.primary.title1[0].text} />
    }
  }

  return (
    <Wrapper ref={ref}>
      <SectionTitle>Work</SectionTitle>
      <Nav />
      {projects.length > 0 &&
        projects.map(project => {
          return (
            <Project
              uid={project.uid}
              key={project.uid}
              title={project.data.title[0].text}
              items={project.data.body}
              date={project.data.date}
              description={project.data.excerpt}
              handleDetail={handleDetail}
              images={project.data.images}
            />
          )
        })}
    </Wrapper>
  );
})

export default Projects;
