import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Prismic from 'prismic-javascript'
import Moment from 'react-moment'

const apiEndpoint = 'https://julian-isaac.cdn.prismic.io/api/v2'
const Client = Prismic.client(apiEndpoint)

const Back = styled.div`
  font-size: 1.5vw;
  margin-bottom: 2rem;
  grid-column: span 4;
`;

const Wrapper = styled.div`
  width: 90vw;
  margin: 2rem auto;
  display: grid;
  grid-gap: 2.5vw;
  grid-template-columns: repeat(4, 1fr);

  h1 {
    margin: 0 0 4rem 0;
    font-size: 3vw;
    font-weight: 500;
    line-height: 3vw;
  }

  p {
    font-size: 1vw;
    line-height: 2vw;
  }

  main {
    grid-column: 2 / span 2;

    p {
      font-size: 1.25vw;
      line-height: 2vw;
      color: ${props => props.theme.color.text};
    }
  }

  aside {
    color: ${props => props.theme.color.text};

    h2 {
      margin: 2rem 2rem 0 0;
      font-size: 1.5vw;
      font-weight: 500;
      color: ${props => props.theme.color.heading};

      &:first-of-type {
        margin-top: 0;
      }
    }

    h4 {
      font-size: 1vw;
      font-weight: 500;
      margin: 0;
    }

    time, span {
      font-size: 1vw;
    }

    p {
      font-family: ${props => props.theme.type.text};
      font-size: 1vw !important;
      margin: 0.5rem 0;
    }
  }
`;

const Job = styled.div`
  margin: 0 0 2rem 0;
  color: ${props => props.theme.color.text};

  &:first-of-type {
    margin-top: 2rem;
  }
`;

const About = () => {

  const [data, setData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const doc = await Client.getSingle('about')

      if(doc){
        setData(doc)
      }
    }

    fetchData()
  })

  return (
    <Wrapper>
      <Back><Link to='/'>⤺ Back</Link></Back>
      {data &&
        <>
          <aside>
            <h2>Experience</h2>
            {data.data.body1.map((i, index) => {
              console.log(i)
              return (
                <Job key={index}>
                  <h4 key={index}>{i.primary.company[0].text}</h4>
                  <p>{i.primary.job_title[0].text}</p>
                  <Moment format='MMM Y'>{i.primary.start_date}</Moment> —&nbsp;
                  {i.primary.end_date
                    ? <Moment format='MMM Y'>{i.primary.end_date}</Moment>
                    : <span>Present</span>
                  }
                </Job>
              )
            })}
            <h2>Skills</h2>
            {data.data.skills.map((p, index) => <p key={index}>{p.text}</p>)}
            <h2>Education</h2>
            <p><h4>Parsons: The New School for Fashion Design BFA</h4><time>Class of 2017</time></p>
            <p><h4>Eugene Lang: The New School for Film BA</h4><time>Class of 2017</time></p>
            <p><h4>Parsons: The New School for Fashion Design AAS</h4><time>Class of 2014</time></p>
            <p><h4>Albany High School, Albany CA</h4><time>Class of 2010</time></p>
            <h2>Awards</h2>
            <p><h4>Kering - Empowering Imagination</h4> Finalist</p>
            <p><h4>Eyes on Talent / Parsons Innovation Award 2017</h4> Finalist</p>
          </aside>
          <main>
            <h1>{data.data.title[0].text}</h1>
            {data.data.body.map((p, index) => <p key={index}>{p.text}</p>)}
          </main>
        </>
      }
    </Wrapper>
  )
}

export default About;
