import React from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import Collage from './Collage'
import Button from './Button'
import { device } from '../utils/devices'

const Wrapper = styled.div`
  margin-bottom: 6rem;
  color: red;
  background: ${props => props.theme.color.bg};

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5vw;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    font-family: ${props => props.theme.type.text};
    font-size: 6vw;
    font-weight: 500;
    margin: 0 0 2rem;

    @media ${device.laptop} {
      font-size: 2.5vw
    }

    time, span {
      font-family: ${props => props.theme.type.text};
      font-weight: 100;
      margin-left: 1rem;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  button, a {
    margin-top: auto;
    margin-top: 2rem;
    display: block;
    outline: 0;
    width: 100%;
  }
`;

const Desc = styled.div`
  font-size: 16px;
  line-height: 20px;

  p {
    margin-top: 0.5rem;
    color: ${props => props.theme.color.text};
  }
`;

const Project = ({ uid, title, date, items, description, handleDetail, images }) => {

  return (
    <Wrapper>
      <Info>
        <h2>{title}<time><Moment format='Y'>{date}</Moment></time></h2>
        <Desc>{description.map((p, index) => <p key={index}>{p.text}</p>)}</Desc>
        <Link to={`/work/${uid}`}><Button>View Project</Button></Link>
      </Info>
      <Collage
        images={images}
      />
    </Wrapper>
  )
}

export default Project
