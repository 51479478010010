import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Audio = styled.div`
  width: 100%;

  .player {
    width: 80% !important;
    height: 100% !important;
    margin: 0 auto;

    video {
      outline: 0;
    }
  }
`;

const Poster = styled.div`
  cursor: pointer;
  width: 30vw;
  height: 30vw;
  margin: 0 auto;

  img {
    width: 100%;
  }
`;

const DetailsVideo = ({ url, poster, title }) => {
  const [playing, setPlaying] = useState(false)

  return (
    <Audio>
      {title}
      <ReactPlayer
        className='player'
        url={url}
        playing={playing}
        controls
      />
    </Audio>
  )
}
export default DetailsVideo;
