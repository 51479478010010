import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  text-align: center;
  font-family: 'Ogg';
  color: ${props => props.theme.color.heading};

  h2 {
    font-weight: normal;
    font-size: 10vw;
    margin: 5vh 0 4rem;
  }
`;

const SectionTitle = ({ children }) => (
  <Title><h2>{children}</h2></Title>
);

export default SectionTitle;
