import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
// import onDraggableStop from '../utils/onDraggableStop'

const Toggle = styled.div`
  font-size: 0.75vw;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  text-transform: none;
  justify-content: space-between;
  visibility: hidden;
  color: ${props => props.theme.color.text};
`;

const Image = styled.div`
  font-family: ${props => props.theme.type.text};
  text-transform: uppercase;
  grid-column: span 3;
  font-size: 2.5vw;
  width: fit-content;
  cursor: move;
  left: 0;
  position: absolute;
  mix-blend-mode: darken;
  /* border: 2px solid black; */
  z-index: ${props => props.zIndex};

  picture {
    background: ${props => props.theme.color.text};
    display: block;
  }

  &:hover {
    ${Toggle} {
      visibility: visible;
    }
  }

  img {
    width: ${props => props.width && `${props.width}px`};
    filter: grayscale(1);
    pointer-events: none;
    mix-blend-mode: screen;
    display: block;
    /* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2); */
  }
`;

function getRandomInteger(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

const ProjectDocument = ({ title, file, parentWidth, parentHeight, topZ, setTopZ, handleDetail }) => {

  const [zIndex, setZIndex] = useState(1)
  const [docWidth, setDocWidth] = useState(0)

  useEffect(() => {
    const width = getRandomInteger(parentWidth * .15, parentWidth * .3)
    setDocWidth(width)
  }, [parentWidth])

  const x = Math.floor(Math.random() * ((parentWidth * .8)  - docWidth)) + 1;
  const y = Math.floor(Math.random() * (parentHeight/2 - docWidth)) + 1;

  const handleDrag = () => {
    const newTop = topZ + 1;
    setTopZ(newTop)
    setZIndex(newTop)
  }

  return (
    <Draggable
      axis='both'
      bounds='.draggable-bounds'
      scale={1}
      cancel='.toggle'
      onStart={handleDrag}
      defaultPosition={{x: x, y: y }}
    >
    <Image className='image' zIndex={zIndex} width={docWidth}>
      <picture>
        <img src={file} alt={title} />
      </picture>
      <Toggle className='toggle' onClick={handleDetail}>
        <span>{title}</span>
        <span>View ↗</span>
      </Toggle>
    </Image>
    </Draggable>
  )
}

export default ProjectDocument;
