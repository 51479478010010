import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.small ? `4rem` : 0 };
`;

const Title = styled.h2`
  font-family: ${props => props.theme.type.text};
  font-size: 2vw;
`;

const Viewer = styled.div`
  span {
    text-align: center;
    font-size: 1vw;
    display: block;
    margin-top: 2rem;
  }

  canvas {
    cursor: pointer;
  }
`;

const DetailsGallery = forwardRef(({ file, title, small }, ref) => {

  const [pages, setPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const handleLoad = (numPages) => {
    setPages(numPages)
    setCurrentPage(1)
  }

  return (
    <Details small={small} ref={ref}>
      <Document
        file={file}
        onLoadSuccess={({ numPages}) => handleLoad(numPages)}
      >
        {pages > 0 &&
          <Viewer>
            <Title></Title>
            <Page
              className='page'
              height={window.innerHeight * .8}
              width={small && window.innerWidth * .5}
              pageNumber={currentPage}
              onClick={() => {
                if(currentPage === pages){
                  setCurrentPage(1)
                } else {
                  setCurrentPage(() => currentPage + 1)
                }
              }}
            />
            {!small && <span>Page {currentPage} of {pages}</span>}
          </Viewer>
        }
      </Document>
    </Details>
  )
})

export default DetailsGallery
