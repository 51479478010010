import React, { useEffect, useState, createRef } from 'react'
import styled from 'styled-components'
import Projects from '../components/Projects'
import Header from '../components/Header'

const Main = styled.main`
  background: ${props => props.theme.color.bg};
  transition: 0.4s all ease-in-out;
  transform: ${props => props.scrolled ? `translate(0, -90vh)` : `translate(0,0)` };
`;

const Home = () => {

  const [scrolled, setScrolled] = useState(false)

  return (
    <Main>
    <Header />
    <Projects />
    </Main>
  )
}

export default Home
